.header {
  z-index: 1100;
}

.toolbar {
  display: flex;
  justify-content: space-between;
}

.logo-section {
  display: flex;
  align-items: center;
}

.header-logo {
  width: 80px; /* Adjusted for smaller screens */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px;
  border-radius: 5vw;
}

.header-title {
  text-align: center;
  flex-grow: 1; /* Allow title to grow and center */
}
