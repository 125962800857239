.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Stack vertically by default */
    padding: 20px;
    min-height: 100vh; /* Full height */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding in width */
  }
  
  .logo {
    width: 100%; /* Full width */
    max-width: 300px; /* Max width for larger screens */
    height: auto; /* Maintain aspect ratio */
    object-fit: contain; /* Ensures image is contained */
    margin-bottom: 20px; /* Space below logo */
  }
  
  .form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px; /* Max width for the form on larger screens */
  }
  
  .form-title {
    font-size: 2rem; /* Responsive font size */
  }
  
  .login-form {
    width: 100%; /* Make form full width */
  }
  
  /* Button styling */
  .login-button {
    background-color: #009688;
    color: #fff;
  }
  
  /* Responsive adjustments */
  @media (min-width: 768px) {
    .login-container {
      flex-direction: row; /* Change to row layout on larger screens */
      justify-content: center; /* Center items */
    }
    .logo {
      margin-right: 40px; /* Space between logo and form */
      max-width: 40vw; /* Adjust logo size for larger screens */
    }
    .form-container {
      max-width: 500px; /* Wider form on larger screens */
    }
  }
  
  @media (max-width: 480px) {
    .form-title {
      font-size: 1.5rem; /* Smaller title font size on mobile */
    }
  }
  