.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #009688;
  color: white;
  padding: 8px 0;
  text-align: center;
}
